@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");

@font-face {
  font-family: "CabinetGrotesk-Medium";
  src: url("../fonts/CabinetGrotesk-Medium.woff2") format("woff2"),
    url("../fonts/CabinetGrotesk-Medium.woff") format("woff"),
    url("../fonts/CabinetGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay-Variable";
  src: url("../fonts/ClashDisplay-Variable.woff2") format("woff2"),
    url("../fonts/ClashDisplay-Variable.woff") format("woff"),
    url("../fonts/ClashDisplay-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

:root {
  --default-lilac: #b59bce;
  --default-bg-color: #f6f6f4;
  --default-black: #121212;
  --default-brd-rad: 10px;
  --secondary-font: "Pinyon Script", cursive;
  --clash-display-variable: "ClashDisplay-Variable";
  --cabinet-medium: "CabinetGrotesk-Medium";
  --menu-content: "menu";
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
  overflow: hidden;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.leva-c-kWgxhW {
  z-index: 100000000 !important;
}

.bot-align {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.top-align {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

body,
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  color: var(--default-lilac);
  font-family: var(--clash-display-variable), BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--default-bg-color);
  overscroll-behavior: none;
}

.js-end {
  justify-self: end;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
  font-family: var(--clash-display-variable);
  padding: 0 10px;
  font-size: 0.9375vw;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
  font-family: var(--clash-display-variable);
  padding: 0 10px;
  font-size: 0.9375vw;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
  font-family: var(--clash-display-variable);
  padding: 0 10px;
  font-size: 0.9375vw;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
  font-family: var(--clash-display-variable);
  padding: 0 10px;
  font-size: 0.9375vw;
}

input::placeholder {
  color: transparent;
  font-family: var(--clash-display-variable);
  padding: 0 10px;
  font-size: 0.9375vw;
}

.def-border-radius {
  border-radius: var(--default-brd-rad);
}

.desk-hidden {
  display: none !important;
}

.ox-hidden {
  overflow-x: hidden !important;
}

.o-hidden {
  overflow: hidden;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.rev-anim {
  animation-direction: reverse !important;
}

.mtb-200 {
  margin: 200px 0 !important;
}

.d-100 {
  animation-delay: 100ms !important;
  transition-delay: 100ms !important;
}

.d-150 {
  animation-delay: 150ms !important;
  transition-delay: 150ms !important;
}

.d-200 {
  animation-delay: 200ms !important;
  transition-delay: 200ms !important;
}

.d-250 {
  animation-delay: 250ms !important;
  transition-delay: 250ms !important;
}

.d-300 {
  animation-delay: 300ms !important;
  transition-delay: 300ms !important;
}

.d-350 {
  animation-delay: 350ms !important;
  transition-delay: 350ms !important;
}

.d-400 {
  animation-delay: 400ms !important;
  transition-delay: 400ms !important;
}

.d-500 {
  animation-delay: 500ms !important;
  transition-delay: 500ms !important;
}

.d-600 {
  animation-delay: 600ms !important;
  transition-delay: 600ms !important;
}

.d-700 {
  animation-delay: 700ms !important;
  transition-delay: 700ms !important;
}

.d-800 {
  animation-delay: 800ms !important;
  transition-delay: 800ms !important;
}

.d-900 {
  animation-delay: 900ms !important;
  transition-delay: 900ms !important;
}

.d-1000 {
  animation-delay: 1000ms !important;
  transition-delay: 1000ms !important;
}

img {
  border-radius: var(--default-brd-rad) !important;
}

/* MARQUEE DESIGN */
.text-wrapper {
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  background-color: var(--default-lilac);
  overflow: hidden;
  width: fit-content;
  animation: move-rtl 25s linear infinite;
  border: solid 1px var(--default-bg-color);
}

.text-wrapper > h1 {
  z-index: 1;
  font-family: var(--clash-display-variable);
  font-weight: 400;
  font-size: 2.604vw;
  margin: 30px 0;
  text-transform: uppercase;
  color: white;
  white-space: nowrap;
  padding: 0 25px;
  letter-spacing: 1px;
}

.r-m4 {
  transform: rotate(-4deg);
}

.r-4 {
  transform: rotate(4deg);
}

.r-5 {
  transform: rotate(5deg);
}

.r-7 {
  transform: rotate(7deg);
}

.z-80 {
  z-index: 80;
  position: relative;
}

.z-100 {
  z-index: 100;
  position: relative;
}

.z--1 {
  position: relative;
  z-index: -1;
}

@keyframes move-rtl {
  to {
    transform: translateX(-50%);
  }
}

.cta-wp {
  z-index: 100000;
  position: relative;
  width: 97vw;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2vh 0;
}

.text-anim-wp {
  overflow: hidden;
  width: 100%;
  margin: auto;
}

.text-anim {
  display: inline-block !important;
  transform: translateY(3em) rotate(3deg);
  animation: appear 0.7s ease-in-out forwards;
  overflow: hidden;
  font-size: 2.083vw;
  font-weight: 500;
}

@keyframes appear {
  to {
    transform: translateY(0) rotate(0deg);
  }
}

.smoothScroll {
  height: 100vh;
}

.button-outline {
  pointer-events: auto;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  border: 1px solid var(--default-lilac);
  padding: 0.4em 2em;
  margin: 0;
  font-family: var(--clash-display-variable) !important;
  font-weight: 500;
  font-size: 0.9375vw;
  position: relative;
  display: inline-block !important;
  text-transform: uppercase !important;
  border-radius: 25px;
  color: var(--default-lilac) !important;
}

.button--atlas-outline {
  font-family: var(--clash-display-variable) !important;
  font-weight: 500;
  width: fit-content;
  max-height: 23px;
}

.button--atlas-outline > span {
  display: inline-block !important;
}

.button--atlas-outline:hover > span {
  opacity: 0;
}

@media (max-width: 1080px) {
  .button--atlas-outline:hover > span {
    opacity: 1 !important;
  }

  .marqueebutton-outline {
    display: none;
  }

  .button--atlas-outline:hover .marquee__inner-outline {
    opacity: 0;
  }
}

.marqueebutton-outline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}

.marquee__inner-outline {
  width: fit-content;
  display: flex !important;
  position: relative;
  --offset: 1rem;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marqueebutton 3s linear infinite !important;
  animation-play-state: paused;
  opacity: 0;
}

.button--atlas-outline:hover .marquee__inner-outline {
  animation-play-state: running;
  opacity: 1;
  transition-duration: 0.4s;
}

.marqueebutton-outline span {
  text-align: center;
  white-space: nowrap;
  padding: 0.4em 0.3em;
}

@keyframes marqueebutton {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.btn-hover-reset {
  animation: marqueebutton 3s linear infinite !important;
  animation-play-state: running !important;
  opacity: 1;
}

#hide-btn-default {
  visibility: hidden;
}

/* ================================= loader styles */

.loader-wp,
.transition-wp,
.transition-wp-in {
  z-index: 999999;
  height: 1000vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
}

.transition-wp {
  position: fixed !important;
  background-color: #111;
  transition: top 1.2s cubic-bezier(0.57, 0.06, 0.07, 0.97);
  top: 100vh;
}

.transition-wp-in {
  top: 0;
  position: fixed !important;
  background-color: #111;
}

.homeTransition .transition-wp-in {
  display: none;
}

.animateTransition-in {
  transition: top 1.2s cubic-bezier(0.57, 0.06, 0.07, 0.97);
  transition-delay: 800ms;
  top: 100vh !important;
}

.animateTransition {
  transition: top 1.2s cubic-bezier(0.57, 0.06, 0.07, 0.97);
  top: 0 !important;
}

.loader-wp {
  top: 0;
}

#myProgress {
  width: 90%;
  background-color: transparent;
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
}

#myBar {
  width: 0%;
  height: 3px;
  background-color: transparent;
  text-align: left;
  line-height: -50px;
  color: var(--default-lilac);
  font-size: 8vw !important;
  font-weight: 500;
}

.appearAnim {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.appearAnim > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: top 1.2s cubic-bezier(0.57, 0.06, 0.07, 0.97);
  background-color: #111;
}

.loadingAnim {
  top: 100vh !important;
}

.no-js {
  display: block;
}

.js,
.js * {
  display: none !important;
}

/* ====================================================== */

/* ================================== about page styles */

.about-page {
  overflow: hidden !important;
  min-height: fit-content;
}

.about-wp {
  width: 100vw;
  display: grid;
  grid-template-columns: 43% 57%;
}

.about-title {
  margin: 2vh auto 0 auto;
  font-size: 8.958vw;
  font-weight: 500;
  width: 90%;
  line-height: 100px;
}

.about-title span {
  font-family: var(--secondary-font);
}

.title-about-wp,
.p-data-wp {
  width: 100%;
  height: inherit;
}

.p-data-wp {
  display: grid;
  grid-template-columns: 25% auto;
}

.title-cv,
.parag-cv {
  font-weight: 500;
  margin: 0;
  font-family: var(--clash-display-variable);
}

.title-cv {
  text-transform: uppercase;
  font-size: 1.041vw;
}

.parag-testimonial {
  width: 90%;
  font-weight: 500;
  font-size: 1.5625vw;
  margin: 0;
}

.parag-cv {
  width: 90%;
  font-size: 2.083vw;
}

.about-page .parag-cv {
  font-size: 1.75vw;
}

.work-pos-wp {
  font-family: var(--clash-display-variable);
  margin-top: 60px;
  font-weight: 500;
  font-size: 1.041vw;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  place-items: center;
}

.work-cta-container {
  position: absolute;
  bottom: 0;
  display: block !important;
}

.rel {
  position: relative;
}

.cv-job {
  font-size: 1.041vw;
  margin: 0;
  font-weight: 500;
}

.cv-grid {
  display: grid;
  grid-template-columns: 30% 60%;
}

.services-grid {
  display: grid;
  grid-template-columns: 20% 20% 20%;
  gap: 3%;
}

.services-grid .cv-job {
  border: solid 1px var(--default-lilac);
  border-radius: 60px;
  text-align: center;
}

.parag-cv-wp ul {
  padding: 20px;
  width: 90%;
}

.review {
  display: grid;
  align-items: center;
  grid-template-columns: 5% 95%;
}

.review p {
  font-weight: 500;
  font-size: 1.041vw;
}

.review img {
  display: inline-block;
}

.personal-img-wp {
  width: 90%;
  margin: 18.5vh auto 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 87%;
  position: relative;
  overflow: hidden;
}

hr {
  display: block;
  height: 1px;
  margin: 0;
  border: 0;
  border-top: 1px solid var(--default-lilac);
  width: 90%;
}

.personal-img {
  width: 17vw;
  height: 45vh;
  overflow: hidden;
  position: absolute;
  left: 0;
}

.img-sizes {
  position: absolute;
  top: -4vh;
  left: -10.5vw;
  border-radius: 10px;
  width: 800px;
}

.text-wrapper h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.circle {
  display: block;
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--default-bg-color);
}

/* ====================================================== */

/* ================================== navigation styles */

.navigation {
  position: fixed;
  bottom: -5vh;
  left: 50vw;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.navigation-anim {
  animation: appearNav 1.2s cubic-bezier(0.98, 0.3, 0.3, 0.9) forwards;
}

@keyframes appearNav {
  to {
    bottom: 2.5vh;
  }
}

.menu-toggle {
  position: relative;
  width: 98px;
  height: 30px;
  background-color: var(--default-black);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-weight: 500;
  flex-direction: column-reverse;
  transition: all 0.5s, width 0.5s, height 0.5s;
  transition-timing-function: cubic-bezier(0.98, 0.3, 0.3, 0.9);
}

.menu-toggle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms;
  z-index: -1;
  border-radius: 10px;
  background-color: var(--default-black);
}

.menu-toggle:hover::after {
  transform: scale(1.05);
}

.menu-toggle::before {
  color: var(--default-lilac);
  content: var(--menu-content);
  font-size: 1.041vw;
  text-transform: uppercase;
  font-family: var(--cabinet-medium);
  transition: all 0.5s;
  font-weight: 500;
  position: absolute;
  bottom: 0px;
  margin-bottom: 2px;
}

.menu-toggle.active {
  width: 23.5vw;
  height: 77px;
  border-radius: 10px;
}

.menu-toggle.active ul li {
  opacity: 1;
  display: block;
  font-weight: 500;
  margin: -30px 0 0 0;
  animation: dropDown 0.4s forwards;
  animation-delay: 600ms;
  animation-timing-function: cubic-bezier(0.48, 0.3, 0.3, 0.9);
}

.menu-toggle ul {
  padding: 0 0 30px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.menu-toggle ul li {
  margin: 0;
  list-style: none;
  display: none;
  font-weight: 500;
}

.menu-toggle.active .nav-item {
  animation: showText forwards;
  animation-duration: 0.6s;
  margin: -10px 0 0 0;
}

.nav-item {
  font-size: 1.041vw;
  text-transform: uppercase;
  color: transparent;
  text-decoration: none;
  font-weight: 500;
}

@keyframes dropDown {
  100% {
    color: transparent;
    margin-top: 0;
  }
}

@keyframes showText {
  0% {
    color: transparent;
  }

  80% {
    color: var(--default-lilac);
  }

  100% {
    color: var(--default-lilac);
  }
}

#BTTop {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: black;
  position: fixed;
  bottom: 2.5vh;
  left: 50%;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
}

#BTTOP a {
  width: 30px;
  aspect-ratio: 1;
}

#BTTop img {
  position: absolute;
  left: 23%;
  top: 22%;
}

.appearBTTop {
  animation: appearbtt 1s cubic-bezier(0.98, 0.3, 0.3, 0.9) forwards;
  animation-delay: 1.2s;
}

@keyframes appearbtt {
  1% {
    opacity: 1;
  }

  100% {
    left: calc(50% + 55px);
    opacity: 1;
  }
}

.disappearBTTop {
  animation: disappearbtt 1s cubic-bezier(0.98, 0.3, 0.3, 0.9) forwards !important;
  /* left: calc(50% + 55px) !important; */
  opacity: 1 !important;
}

@keyframes disappearbtt {
  0% {
    left: calc(50% + 55px);
  }

  99% {
    opacity: 1;
  }

  100% {
    left: 50%;
    opacity: 0;
  }
}

/* ======================================================= */

/* ================================== footer styles */
.p-abs-100 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-wp {
  padding-top: 20vh;
  width: 97vw;
  position: relative;
  margin: auto;
  height: 51vh;
}

.img-footer {
  width: 100%;
}

.data-footer-wp {
  display: grid;
  grid-template-columns: 35% 30% 35%;
  grid-template-areas: "piva social privacy";
  width: 100%;
  align-items: center;
  border-top: solid 1px var(--default-lilac);
}

.data-footer-wp p a {
  text-decoration: none;
  color: var(--default-lilac);
  font-size: 0.8vw;
  font-family: var(--clash-display-variable);
  font-weight: 500;
  text-transform: uppercase;
}

.data-footer-wp p {
  font-size: 0.8vw;
  font-weight: 500;
}

.piva {
  grid-area: piva;
  width: 40%;
}

.social {
  grid-area: social;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
}

.privacy {
  grid-area: privacy;
  text-align: right;
}

.devby {
  grid-area: devby;
  text-align: center;
  padding-bottom: 6vh;
}

/* ================================================== */

/* ======================================= works wp styles */

@keyframes showWorks {
  to {
    top: 7vh;
  }
}

.cta-page-switch {
  margin-top: 8vh !important;
}

.cta-page-switch button {
  border: none;
  font-size: 1.6vw;
  font-weight: 500;
  background-color: transparent;
  text-decoration: none;
  color: var(--default-lilac);
  cursor: pointer;
}

.cta-page-switch:nth-of-type(2) {
  text-align: right;
}

.prev-next-style {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.prev-next-style img {
  width: 35px;
  margin-top: 8vh;
  margin-right: 10px;
}

.work-cta-wp,
.works-desc-wp,
.works-grid {
  width: 97vw;
  margin: 10vh auto auto auto;
}

.work-wp {
  height: 80px;
  display: grid;
  grid-template-columns: 1.7% 63% 19% 12.6% 2.7%;
  align-items: center;
  border-bottom: solid 1px var(--default-lilac);
  transition: all 200ms ease-in;
}

.work-wp:hover {
  padding: 0 1%;
}

#follow-img {
  position: absolute;
  pointer-events: none;
  display: none;
  width: 300px;
  /* transition: all 300ms ease; */
}

.work-wp .parag-cv {
  font-size: 1.8vw;
}

.dec-none {
  text-decoration: none !important;
  color: var(--default-lilac);
}

.bt {
  border-top: solid 1px var(--default-lilac);
}

.work-cta-wp {
  padding: 20vh 0 10vh 0;
}

.works-desc-wp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.works-desc-wp p {
  margin: 0;
}

.works-desc-wp a {
  width: fit-content;
}

.works-desc-wp div:last-of-type {
  justify-self: flex-end;
  flex-direction: inherit;
}

.works-desc-wp div:last-of-type img {
  justify-self: flex-end;
  margin-left: 10px;
  margin-right: 0;
}

.work-subtitle {
  font-size: 0.7291vw;
  font-weight: 500;
}

.work-p {
  font-size: 2.083vw;
  font-weight: 500;
}
.see-all-btn-section {
  padding: 0 0 50px;
  text-align: center;
  margin-bottom: 10vh;
}

.see-all-btn {
  color: #b59bce;
  text-decoration: underline;
  font-size: 10vw;
  transition: all 0.2s ease-in-out;
}

.see-all-btn:hover {
  content: "(See all)";
  letter-spacing:2px;
  color:#a485c2;
}

/* different grids per page */

.works-grid {
  display: grid;
  gap: 20px;
}

.row {
  width: 100%;
  height: 90vh;
  border-radius: 10px;
}

.row img {
  width: 100%;
  max-height: 100%;
  margin: auto;
}

#guia #first {
  background-image: url("../img/guia/1.jpg");
}

#guia #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/guia/2-1.png");
}

#guia #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/guia/2-2.png");
}

#guia .row-sm {
  background-image: url("../img/guia/3.png");
  height: 37vh;
}

#guia #fourth {
  background-image: url("../img/guia/4.png");
}

#guia #fifth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/guia/5-1.png");
}

#guia #fifth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/guia/5-2.png");
  background-size: 200%;
}

#branchetti {
  grid-template-areas:
    "frleft frright"
    "second second"
    "third third"
    "forleft forright"
    "fifth-sm fifth-sm"
    "fifth fifth";
}

#branchetti .row:first-of-type {
  background-color: black;
  display: grid;
  place-items: center;
  grid-area: frleft;
}

#branchetti .row:first-of-type > img,
#branchetti .row:nth-child(2) > img {
  width: 70%;
  border-radius: 0 !important;
}

#branchetti .row:nth-child(2) {
  background-image: url(../img/branchetti/bg-first.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  grid-area: frright;
}

#branchetti .row:nth-child(3) {
  background-color: #bababa;
  grid-area: second;
  place-items: center;
  display: grid;
}

#branchetti .row:nth-child(3) > .inner-row {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

#branchetti .row:nth-child(3) > .inner-row img {
  border-radius: 0 !important;
  height: 40vh;
}

#branchetti .row:nth-child(3) > .inner-row div:first-of-type img {
  margin: -5vh 0 0 0;
}

#branchetti .row:nth-child(3) > .inner-row div:last-of-type img {
  margin: 5vh 0 0 0;
}

#branchetti .row:nth-child(4) {
  background-image: url(../img/branchetti/third.png);
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: third;
}

#branchetti .row:nth-child(5) {
  background-image: url(../img/branchetti/fourth.png);
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: forleft;
}

#branchetti .row:nth-child(6) {
  background-color: black;
  grid-area: forright;
  display: grid;
  place-items: center;
}

#branchetti .row:nth-child(6) > .inner-row {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

#branchetti .row:nth-child(6) > .inner-row img {
  border-radius: 0 !important;
}

#branchetti .row:nth-child(6) .inner-row > div:nth-child(1) {
  display: grid;
  justify-content: flex-end;
}

#branchetti .row:nth-child(6) .inner-row > div:nth-child(2) {
  display: grid;
  justify-content: flex-start;
}

#branchetti .row:nth-child(6) > .inner-row div:first-of-type img {
  margin: -3vh 0 0 0;
}

#branchetti .row:nth-child(6) > .inner-row div:last-of-type img {
  margin: 3vh 0 0 0;
}

#branchetti .row:nth-child(8) {
  background-color: #bababa;
  grid-area: fifth;
  display: grid;
  place-items: center;
}

#branchetti .row:nth-child(8) > .inner-row {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

#branchetti .row:nth-child(8) > .inner-row img {
  border-radius: 0 !important;
}

#cavallini #first {
  background-image: url("../img/cavallini/1.jpg");
}

#cavallini #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/cavallini/2-1.png");
}

#cavallini #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/cavallini/2-2.jpg");
}

#cavallini #third {
  background-image: url("../img/cavallini/3.png");
}

#cavallini #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/cavallini/4-1.png");
}

#cavallini #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/cavallini/4-2.png");
}

#cavallini #fifth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/cavallini/5-1.png");
}

#cavallini #fifth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/cavallini/5-2.png");
}

#branchetti .row-sm {
  grid-area: fifth-sm;
  height: 37vh;
  background-image: url("../img/branchetti/5-sm.png");
}

.works-grid * {
  background-repeat: no-repeat !important;
  background-position: 0 !important;
  background-size: cover !important;
}

.work-grid-2 div {
  border-radius: 10px;
}

#easea #first {
  background-image: url("../img/easea/1.png");
}

#easea #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/easea/2-1.png");
}

#easea #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/easea/2-2.png");
}

#easea #third {
  background-image: url("../img/easea/3.png");
}

#easea #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/easea/4-1.png");
}

#easea #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/easea/4-2.png");
}

#easea #fifth {
  background-image: url("../img/easea/5.png");
}

#mancinelli #first {
  background-image: url("../img/mancinelli/1.png");
}

#mancinelli #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/mancinelli/2-1.png");
}

#mancinelli #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/mancinelli/2-2.png");
}

#mancinelli #third {
  background-image: url("../img/mancinelli/3.png");
}

#mancinelli #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/mancinelli/4-1.png");
}

#mancinelli #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/mancinelli/4-2.png");
}

#mancinelli #fifth {
  background-image: url("../img/mancinelli/5.png");
}

#gastone #first {
  background-image: url("../img/gastone/1.png");
}

#gastone #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/gastone/2-1.png");
}

#gastone #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/gastone/2-2.png");
}

#gastone #third {
  background-image: url("../img/gastone/3.png");
}

#gastone #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/gastone/4-1.png");
}

#gastone #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/gastone/4-2.png");
}

#gastone #fifth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/gastone/5-1.png");
}

#gastone #fifth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/gastone/5-2.png");
}

#chimera #first .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/chimera/1-1.png");
}

#chimera #first .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/chimera/1-2.png");
}

#chimera #second {
  background-image: url("../img/chimera/2.png");
}

#chimera #third {
  background-image: url("../img/chimera/3.png");
}

#chimera #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/chimera/4-1.png");
}

#chimera #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/chimera/4-2.png");
}

#alexandra #first .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/alexandra/1-1.png");
}

#alexandra #first .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/alexandra/1-2.jpg");
}

#alexandra #second {
  background-image: url("../img/alexandra/2.png");
}

#alexandra #third .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/alexandra/3-1.png");
}

#alexandra #third .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/alexandra/3-2.png");
}

#alexandra #fourth {
  background-image: url("../img/alexandra/4.png");
}

#viaggi #first .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/viaggi/1-1.png");
}

#viaggi #first .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/viaggi/1-2.jpg");
}

#viaggi #second {
  background-image: url("../img/viaggi/2.png");
}

#viaggi #third {
  background-image: url("../img/viaggi/3.png");
}

#viaggi #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/viaggi/4-1.png");
}

#viaggi #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/viaggi/4-2.png");
}

#viaggi #fifth {
  background-image: url("../img/viaggi/5.png");
}

#orchestra #first .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/orchestra/1-1.png");
}

#orchestra #first .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/orchestra/1-2.png");
}

#orchestra #second {
  background-image: url("../img/orchestra/2.png");
}

#orchestra #third .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/orchestra/3-1.png");
}

#orchestra #third .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/orchestra/3-2.png");
}

#orchestra #fourth {
  background-image: url("../img/orchestra/4.png");
  height: 53vh;
  border-radius: 10px;
}

#orchestra #fifth {
  background-image: url("../img/orchestra/5.png");
}

#orchestra #sixth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/orchestra/6-1.png");
}

#orchestra #sixth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/orchestra/6-2.png");
}

#agricola #first {
  background-image: url("../img/agricola/1.png");
}

#agricola #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/agricola/2-1.png");
}

#agricola #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/agricola/2-2.png");
}

#agricola #third {
  background-image: url("../img/agricola/3.png");
}

#agricola #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/agricola/4-1.png");
}

#agricola #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/agricola/4-2.png");
}

#agricola #fifth {
  background-image: url("../img/agricola/5.png");
}

#agricola #sixth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/agricola/6-1.png");
}

#agricola #sixth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/agricola/6-2.png");
}

#pegaso #first {
  background-image: url("../img/pegaso/1.png");
}

#pegaso #second {
  background-image: url("../img/pegaso/2.png");
}

#pegaso #third .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/pegaso/3-1.png");
}

#pegaso #third .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/pegaso/3-2.png");
}

#pegaso #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/pegaso/4-1.png");
}

#pegaso #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/pegaso/4-2.png");
}

#pegaso #fifth {
  background-image: url("../img/pegaso/5.png");
}

#percorsi #first {
  background-image: url(../img/percorsi/Mask\ group.png);
}

#percorsi #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/percorsi/Group\ 115.png");
}

#percorsi #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/percorsi/Group\ 116.png");
}

#percorsi #third .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/percorsi/Group\ 223.png");
}

#percorsi #third .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/percorsi/Mask\ group-1.png");
}

#percorsi #fourth {
  background-image: url("../img/percorsi/Group\ 224.png");
}

#percorsi #fifth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/percorsi/Group\ 112.png");
}

#percorsi #fifth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/percorsi/Mask\ group-2.png");
}

#percorsi #sixth {
  background-image: url("../img/percorsi/Group\ 113.png");
}

#bigne #first {
  background-image: url("../img/bigne/1.png");
}

#bigne #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/bigne/2-1.png");
}

#bigne #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/bigne/2-2.png");
}

#bigne #third {
  background-image: url("../img/bigne/3.png");
}

#bigne #fourth {
  background-image: url("../img/bigne/4.png");
}

#bigne #fifth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/bigne/5-1.png");
}

#bigne #fifth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/bigne/5-2.png");
}

#bigne #sixth {
  background-image: url("../img/bigne/6.png");
}

#bigne #seventh .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/bigne/7-1.png");
}

#bigne #seventh .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/bigne/7-2.png");
}

#cesare #first {
  background-color: #e8e6e3;
  display: grid;
  place-items: center;
}

#cesare #first img {
  width: 50%;
}

#cesare #second .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/cesarelampronti/2-1.png");
}

#cesare #second .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/cesarelampronti/2-2.png");
}

#cesare #third {
  background-color: #265035;
  display: grid;
  place-items: center;
}

#cesare #third div {
  width: 70%;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

#cesare #third img:nth-of-type(1) {
  margin: -5vh 0 0 0;
}

#cesare #third img:nth-of-type(2) {
  margin: 5vh 0 0 0;
}

#cesare #fourth .work-grid-2 div:nth-of-type(1) {
  background-image: url("../img/cesarelampronti/4-1.png");
}

#cesare #fourth .work-grid-2 div:nth-of-type(2) {
  background-image: url("../img/cesarelampronti/4-2.png");
}

#cesare #fifth {
  background-color: #e8e6e3;
  display: grid;
  place-items: center;
}

#cesare #fifth div {
  width: 70%;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

#cesare #fifth img {
  width: 70%;
}

#vanni {
  grid-template-areas:
    "first first"
    "sl sr"
    "third third"
    "fourth fourth"
    "fl fr";
}

#vanni img {
  width: 100%;
}

#vanni .row:nth-child(1) {
  background-color: black;
  grid-area: first;
  display: grid;
  place-items: center;
}

#vanni .row:nth-child(1) > img {
  width: 50%;
  border-radius: 0 !important;
}

#vanni .row:nth-child(7) > img {
  width: 33%;
  border-radius: 0 !important;
}

#vanni .row:nth-child(2) > img,
#vanni .row:nth-child(3) > img {
  width: 70%;
  border-radius: 0 !important;
}

#vanni .row:nth-child(2) {
  display: grid;
  place-items: center;
  background-color: #e2e2e2;
  grid-area: sl;
}

#vanni .row:nth-child(3) {
  display: grid;
  place-items: center;
  background-image: url(../img/vanni/sr.png);
  grid-area: sr;
}

#vanni .row:nth-child(4) {
  background-image: url(../img/vanni/vanni-third.png);
  grid-area: third;
}

#vanni .row:nth-child(5) {
  background-color: #000;
  grid-area: fourth;
  display: grid;
  place-items: center;
}

#vanni .row:nth-child(5) .inner-row {
  width: 50%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

#vanni .row:nth-child(5) .inner-row img {
  border-radius: 0 !important;
}

#vanni .row:nth-child(6) {
  background-image: url(../img/vanni/vanni-fifth.png);
  grid-area: fl;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#vanni .row:nth-child(7) {
  display: grid;
  place-items: center;
  background-color: #e2e2e2;
  grid-area: fr;
}

/* ========================================================= */

/* =================================================== 404 styles */
.footer-not-found canvas {
  display: none !important;
}

.footer-not-found .footer-wp {
  padding: 20vh 0 0 0;
}

.wp-404 {
  height: 80vh;
  width: 100vw;
  display: grid;
  justify-content: center;
}

.txt-wp-404 {
  margin: 8vh 0 0 0;
  height: 50vh;
  width: 55vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
}

.txt-wp-404 p {
  font-size: 25vw;
  font-family: var(--secondary-font);
  text-align: center;
  margin: 0;
}

.balloon-wp-404 {
  width: 100%;
  height: 100%;
}

.balloon-wp-404 > div {
  left: 0.5vw !important;
}

.logo-wp-404 {
  width: fit-content;
  height: 8vh;
  display: grid;
  place-items: center;
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.logo-wp-404 img {
  width: 15vw;
}

/* =============================================================== */

/* =============================== home styles */
.f-home-section {
  height: 97vh;
  width: 97vw;
  margin: auto;
  margin-bottom: 10vh;
  position: relative;
}

.main-logo {
  width: 100%;
  transform: translateY(27vh);
}

.appearLogo {
  display: inline-block !important;
  animation: appearLogo 1.2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes appearLogo {
  to {
    transform: translateY(0);
  }
}

.main-logo-wp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.main-title-wp {
  width: 40vw;
  height: 20vh;
  margin: -6vh auto auto auto;
  display: flex;
  flex-direction: column;
}

.main-title-wp div {
  margin: 0;
  width: 100%;
  text-align: center;
}

.main-title-wp p {
  margin: 0;
  line-height: 1;
}

.main-title-h {
  font-size: 3.6458vw;
  font-weight: 500;
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateY(3em);
}

.appearTitle {
  display: inline-block !important;
  animation: appearInitial 0.9s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.appearTitle:nth-of-type(1) {
  transition-delay: 300ms;
}

.appearTitle:nth-of-type(2) {
  transition-delay: 600ms;
}

.appearTitle:nth-of-type(3) {
  transition-delay: 900ms;
}

@keyframes appearInitial {
  to {
    transform: translateY(0);
  }
}

.cursive-title-h {
  font-size: 2.604vw;
  font-family: var(--secondary-font);
}

.subtitle-h {
  font-size: 1.1vw;
  font-weight: 500;
  transform: translateY(3em);
}

.main-p-wp {
  width: 100vw;
  margin: 0 auto 55vh auto;
}

.mp-wp {
  display: grid;
  grid-template-columns: 60% 40%;
  height: 30vh;
}

.mp-wp .per-hobbies {
  margin: 0;
}

.mp-wp .per-hobbies p {
  width: 32vw;
  margin: auto;
}

.mp-wp .parag-cv {
  /* font-size:2.7083vw !important; */
  font-size: 2.3083vw !important;
}

.pd-60-y {
  padding: 60px 0;
}

.pd-60-bot {
  padding-bottom: 60px;
}

.b-bottom {
  border-bottom: solid 1px var(--default-lilac);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 65vh;
  align-items: flex-end;
}

.work-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  place-items: center;
  height: inherit;
  column-gap: 20px;
}

.work-grid-2 > div {
  height: inherit;
  width: 100%;
}

.grid-2 > div {
  width: 90%;
  margin: 0 auto;
}

.first-col-g2 {
  align-self: end;
}

.second-col-g2 p {
  width: 50%;
  margin: auto;
  font-size: 1.1666vw;
  line-height: 1.5;
}

.per-hobbies {
  margin-top: 12vh;
}

.per-hobbies p,
.more-info p {
  text-transform: uppercase;
  font-size: 1vw;
  line-height: 2.2;
  position: relative;
}

.more-info {
  position: relative;
  margin: 7vh 0 0 0;
}

.img-home-l {
  width: 42%;
}

.main-p-wp .text-anim-wp {
  width: 90%;
  margin: auto;
}

.main-img-wp {
  width: 97vw;
  margin: 20vh auto 0;
  height: 580vh;
  position: relative;
}

.info-work {
  position: relative !important;
  display: grid;
  width: 100% !important;
  grid-template-columns: 60% 20% 20%;
}

.info-work p {
  font-size: 0.8vw !important;
}

#monica {
  grid-template-columns: 50% 40% 10%;
}

#monica .p-num {
  padding-right: 10px;
}

.work-wp .p-num {
  display: none;
  text-align: right;
  width: 80%;
}

.work-wp .services-grid {
  grid-template-columns: 1fr;
  width: fit-content;
}

.work-wp .services-grid .cv-job {
  padding: 0 10px;
  text-transform: uppercase;
  border-radius: 50px;
  font-size: initial;
}

#banner-works {
  margin: 200px 0;
}

.text-center {
  text-align: center;
}

.proj-date {
  text-align: right;
}

.main-img-wp div {
  position: absolute;
}

.main-img-wp img {
  transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.main-img-wp img:hover {
  border-radius: 50% !important;
}

.main-img-wp div a {
  text-decoration: none;
  color: var(--default-lilac);
}

.main-img-wp > div:nth-of-type(1) {
  width: 32vw;
  top: 0;
  left: 0;
}

.main-img-wp > div:nth-of-type(2) {
  width: 31.6vw;
  top: 25vh;
  right: 0;
}

.main-img-wp > div:nth-of-type(3) {
  width: 35.7vw;
  top: 115vh;
  left: 8.5vw;
}

.main-img-wp > div:nth-of-type(4) {
  width: 31.6vw;
  top: 175vh;
  right: 5vw;
}

.main-img-wp > div:nth-of-type(5) {
  width: 23.5vw;
  top: 235vh;
  left: 0;
}

.main-img-wp > div:nth-of-type(6) {
  width: 41vw;
  top: 275vh;
  right: 2vw;
}

.main-img-wp > div:nth-of-type(7) {
  width: 33vw;
  top: 340vh;
  left: 7.5vw;
}

.main-img-wp > div:nth-of-type(8) {
  width: 29vw;
  top: 420vh;
  right: 0;
}

.main-img-wp > div:nth-of-type(9) {
  width: 35.5vw;
  top: 480vh;
  left: 0;
}

.main-img-wp img {
  width: 100%;
}

.img-home {
  width: 60%;
}

.sx,
.dx {
  width: 100%;
  background-color: var(--default-bg-color);
  position: absolute;
  height: 70%;
  top: 0;
}

/* .fadeInSx-animation {
  animation-delay: 1s;
  animation: .8s fadeInSx .7s cubic-bezier(.77,0,.175,1) forwards;
} */

/* @keyframes fadeInSx {
  0% {
    left: 0;
  }
  100% {
   left: 100%;
  }
} */

.fadeInUp-animation {
  animation: 1s fadeInUp 0.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

@keyframes fadeInUp {
  0% {
    top: 0;
  }

  100% {
    top: -70%;
  }
}

.view-more {
  visibility: hidden;
  position: absolute;
  z-index: 1000;
}

.view-more .button-outline {
  background-color: white;
}

.p-num {
  font-family: var(--secondary-font);
  display: inline-block;
  margin: 0;
  padding: 0;
  height: inherit;
  width: 10%;
  font-size: 0.75vw;
}

.p-num-par {
  font-family: var(--secondary-font);
  display: inline-block;
  margin: 0;
  padding: 0;
  height: inherit;
  width: 10%;
  font-size: 1vw;
}

.wp-404 a {
  text-decoration: none;
  color: var(--default-lilac);
}

.circular-btn,
.circular-btn-mob {
  width: 2.5vw;
  aspect-ratio: 1;
  border-radius: 50% !important;
  padding: 0 !important;
  margin: 0;
  position: absolute;
  background-color: var(--default-lilac);
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.circ-btn-anim:hover {
  width: 2.65vw !important;
}

.rowAnim {
  width: 1%;
  height: 2px;
  background-color: var(--default-lilac);
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translateY(-50%);
}

.rowAnim-mob {
  left: 50%;
}

.animateRow {
  animation: animRow 1.3s cubic-bezier(0.98, 0.3, 0.3, 0.9) forwards;
  animation-delay: 1s;
}

@keyframes animRow {
  to {
    width: 38%;
  }
}

.circular-btn .material-symbols-outlined,
.circular-btn-mob .material-symbols-outlined {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 300;
  font-size: 1.4062vw;
}

.btnP-anim {
  animation: btn-rotation 1.3s cubic-bezier(0.98, 0.3, 0.3, 0.9) forwards;
  animation-delay: 1s;
}

.btnP-anim-mob {
  animation: btn-rotation2 1.3s cubic-bezier(0.98, 0.3, 0.3, 0.9) forwards;
  animation-delay: 1s;
}

@keyframes btn-rotation {
  from {
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    left: 70%;
    transform: translate(-50%, -50%) rotate(720deg);
  }
}

@keyframes btn-rotation2 {
  from {
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    left: 90%;
    transform: translate(-50%, -50%) rotate(720deg);
  }
}

.main-p-wp .parag-cv {
  font-size: 3.1562vw;
  transform: translateY(3em) rotate(3deg);
  width: 100%;
}

.main-p-wp .text-anim-wp:first-of-type .parag-cv {
  width: 90%;
}

.main-p-wp .text-anim-wp:last-of-type {
  position: relative;
}

.main-p-wp .text-anim-wp:last-of-type .parag-cv {
  width: fit-content;
}

.a-35 {
  animation-duration: 35s;
}

.a-40 {
  animation-duration: 40s;
}

.a-50 {
  animation-duration: 50s;
}

/* ========================================== */

/* ========================================== form styles */

.rel {
  position: relative;
}

/* TODO */
#outerContainer {
  display: block;
  width: 100%;
  height: 16vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#container {
  position: relative;
  width: 98vw;
  height: 55px;
  background-color: transparent;
  border: 1px solid var(--default-lilac);
  margin: 0;
  border-radius: 36px;
  touch-action: none;
  display: grid;
  align-items: center;
}

#container.select-left .left span {
  opacity: 1;
  color: white;
}

#container.select-right .right span {
  opacity: 1;
  color: white;
}

#item {
  position: relative;
  width: 55px;
  height: 45px;
  border-radius: 36px;
  transition: all 0.05s cubic-bezier(0.57, 0.06, 0.07, 0.97);
  touch-action: none;
  user-select: none;
  display: grid;
  place-items: center;
  touch-action: none;
}

.select-left #item::after {
  position: absolute;
  top: -5px;
  left: 0;
  width: 55px;
  border-radius: 42px;
  height: calc(100% + 10px);
  background-color: var(--default-lilac);
  content: "";
  touch-action: none;
}

.select-right #item::after {
  position: absolute;
  top: -5px;
  left: 0;
  width: 55px;
  border-radius: 42px;
  height: calc(100% + 10px);
  content: "";
  background-color: white;
  border: 1px solid #e3e8ec;
  touch-action: none;
}

.select-right#container {
  background-color: var(--default-lilac);
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}

.left span {
  font-family: sans-serif;
  font-size: 20px;
  pointer-events: none;
  user-select: none;
  color: var(--default-lilac);
  transition: all 0.2s ease;
  font-weight: 500;
  z-index: 100;
  margin-bottom: 5px;
}

.right {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
  top: 0;
  right: 50%;
  pointer-events: none;
  user-select: none;
  transform: translateX(50%);
}

.right span {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  pointer-events: none;
  user-select: none;
  color: var(--secondary-font);
  transition: all 0.2s ease;
  font-weight: 500;
  text-transform: uppercase;
}

/* TODO */

.form-wrapper {
  width: 97vw;
  margin: auto;
  margin-bottom: 8vh;
}

.form-title {
  font-size: 2.8645vw;
  font-weight: 500;
  font-family: var(--clash-display-variable);
  text-align: center;
  width: 100%;
}

.form-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.title-form {
  margin: 0;
  font-size: 1.041vw;
  font-weight: 500;
  font-family: var(--clash-display-variable);
}
.form-column {
  width: 30%;
  flex-grow: 1;
}
.form-column.cta {
  width: 50px;
}

.form-item {
  margin-top: 10px;
  height: 40px;
  border: solid 1px;
  border-color: var(--default-lilac);
  outline: none;
  background-color: var(--default-bg-color);
  width: 100%;
  color: var(--default-lilac);
  font-size: 18px;
}

.form-button {
  width: 45px;
  aspect-ratio: 1;
  border-color: transparent;
  border-radius: 50%;
  background-color: var(--default-lilac);
  cursor: pointer;
  margin-top: 10px;
}

.material-symbols-outlined {
  color: white;
}

.agree-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  height: 21px;
  cursor: pointer;
  font-size: 1.1458vw;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  aspect-ratio: 1;
  background-color: var(--default-bg-color);
  border: solid 1px var(--default-lilac);
  border-radius: 2px;
}

.container:hover input ~ .checkmark {
  background-color: var(--default-bg-color);
}

.container input:checked ~ .checkmark {
  background-color: var(--default-lilac);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--default-bg-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ======================================= */

/* ========================================== responsive styles*/

@media screen and (max-width: 1600px) {
  #view-more {
    display: none;
  }
  .personal-img {
    width: 20vw;
  }

  .img-sizes {
    top: -7vh;
    left: -15.5vw;
    border-radius: 7px;
  }

  .menu-toggle::before {
    font-size: 1.2vw;
  }
}

@media screen and (max-width: 1400px) {
  #view-more {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  /* responsive general styles */
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: var(--default-lilac);
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--default-lilac);
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--default-lilac);
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: var(--default-lilac);
  }

  input::placeholder {
    color: var(--default-lilac);
  }

  .mobile-hidden {
    display: none !important;
    visibility: hidden;
  }

  .text-wrapper.mtb-200 {
    margin: 100px 0;
  }

  .cta-wp {
    display: none;
  }

  .work-wp {
    grid-template-areas:
      "num title title title"
      "fill tipo data freccia";
    grid-template-columns: 1% 74% 10% 20%;
    padding: 2%;
    align-items: center;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 10px;
  }

  .form-column {
    width: 100%;
    flex-grow: 1;
  }
  .form-column.cta {
    width: 50px;
  }

  .txt-wp-404 {
    grid-template-columns: initial;
    align-items: flex-end;
    height: 60vh;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .txt-wp-404 p:nth-of-type(2) {
    align-self: flex-start;
  }

  .txt-wp-404 p {
    font-size: 38vw;
  }

  .logo-wp-404,
  .logo-wp-404 img {
    width: 50vw;
  }

  .footer-not-found .footer-wp {
    height: 40vh;
  }

  .prev-next-style img {
    margin-top: 0;
  }

  .work-wp:hover {
    padding: 2%;
  }

  .work-wp div:nth-child(1) {
    grid-area: num;
  }

  .work-wp div:nth-child(2) {
    grid-area: title;
  }

  .work-wp div:nth-child(3) {
    grid-area: tipo;
  }

  .work-wp div:nth-child(4) {
    grid-area: data;
  }

  .dec-none .parag-cv,
  .dec-none .p-num {
    font-size: 25px !important;
  }

  .work-wp div:nth-child(5) {
    grid-area: freccia;
    display: grid;
    place-items: center;
  }

  .provaDiv .cta-wp {
    z-index: 100000;
    position: relative;
    width: 97vw;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2vh 0;
  }

  .works-mob div {
    width: 97vw;
    margin: 1.5vh auto;
  }

  .works-mob img {
    width: 100%;
  }

  .work-cta-container {
    position: initial;
    margin-bottom: 20px;
  }

  .services-grid .cv-job.mt-20 {
    margin: 10px 0 !important;
  }

  /* responsive about */

  .scrollAbout {
    display: none;
  }

  .img-sizes {
    left: -48vw;
    top: -7vh;
    width: 900px;
  }

  .personal-img {
    width: 90vw;
    height: 60vh;
  }

  .desk-hidden {
    display: block !important;
  }

  .about-wp,
  .p-data-wp {
    display: block;
  }

  .p-data-wp {
    width: 90%;
    margin: auto;
  }

  .about-title {
    font-size: 31.25vw;
    line-height: 80px;
  }

  .b-bottom {
    border: none;
  }

  .about-wp .pd-60-y {
    padding: 0;
  }

  .about-wp .pd-60-bot {
    padding: 0;
  }

  .personal-img-wp {
    flex-direction: column-reverse;
    margin: 5vh auto 7vh auto;
  }

  .personal-img-wp .fl-bot {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .serious-cta {
    margin: 10px 0;
  }

  .parag-cv,
  .parag-testimonial {
    font-size: 1.4583vw;
    width: 100%;
  }

  .title-cv {
    margin: 20px 0;
  }

  .cv-grid {
    display: block;
  }

  .cv-job:nth-child(odd) {
    margin: 10px 0;
  }

  .cv-job:nth-child(even) {
    margin: 0 0 50px 0;
  }

  .parag-cv-wp {
    margin-top: 40px;
  }

  .work-pos-wp {
    display: none;
  }

  .cta-page-switch button {
    font-size: 6.385vw;
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .services-grid .cv-job {
    margin: 10px 0;
  }

  .work-wp .text-center,
  .services-grid .cv-job {
    font-size: 13px !important;
  }

  hr {
    margin: 40px 0;
    width: 100%;
    border-top: 2px solid var(--default-lilac);
  }

  .text-wrapper > h1 {
    font-size: 6.5625vw;
  }

  .circle {
    width: 20px;
  }

  .title-cv-wp.mt-120 {
    margin-top: 70px !important;
  }

  .work-grid-2 {
    display: block;
  }

  #messaggio,
  #email {
    border-top: none;
  }

  /* responsive navbar */

  .menu-toggle.active {
    max-width: 98vw;
  }

  .menu-toggle::before {
    margin-bottom: 5px;
  }

  .nav-item,
  .menu-toggle::before {
    font-size: 3.8333vw;
  }

  /* responsive form */

  .form-title {
    font-size: 9.5625vw;
  }

  .form-grid {
    grid-template-columns: 100%;
  }

  .agree-wrapper {
    flex-wrap: nowrap;
  }

  .title-form {
    font-size: 4.6854vw;
  }

  .piva {
    width: 100%;
    font-size: 4vw;
  }

  .data-footer-wp p a {
    font-size: 4vw;
  }

  .social {
    align-items: center;
    place-items: initial;
  }

  .ln {
    text-align: center;
  }

  .fb {
    text-align: right;
  }

  .main-img-wp > div {
    display: block;
    width: 100% !important;
    position: static;
  }

  .main-img-wp {
    height: fit-content;
    display: grid;
    gap: 0.7vh 0;
    grid-template-columns: 1fr;
  }

  .menu-toggle.active {
    width: 90vw;
  }

  input.form-item::placeholder {
    font-size: 3.5vw;
  }

  /* responsive footer */
  .data-footer-wp {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "piva privacy"
      "social social"
      "devby devby";
  }

  .social,
  .data-footer-wp {
    border-top: solid 2px var(--default-lilac);
    border-bottom: solid 2px var(--default-lilac);
  }

  /* responsive home */

  .works-grid {
    display: flex;
    flex-direction: column;
  }

  .works-desc-wp {
    display: block;
  }

  .cta-page-switch {
    margin: 0 !important;
  }

  .next-wp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 97vw;
  }
  .works-desc-wp {
    margin: 0 auto 23vh auto !important;
  }

  #myProgress {
    top: 85vh;
  }

  #myBar {
    font-size: 18vw !important;
  }

  .works-desc-wp div:nth-child(1) > .work-p {
    font-size: 8vw;
    margin-top: 10vh;
  }

  .works-desc-wp div:nth-child(1) > .work-subtitle:nth-child(2) {
    margin: 8vh 0 0 0 !important;
  }

  .works-desc-wp div:nth-child(1) > .work-subtitle:nth-child(3) {
    margin: 1vh 0 6vh 0 !important;
  }

  .rel.desk-hidden {
    margin: 4vh 0;
  }

  .work-p {
    font-size: 5vw;
    line-height: 1.5;
  }

  .work-subtitle {
    font-size: 3vw;
  }

  .work-cta-wp {
    padding: 10vh 0;
  }

  .button-outline {
    font-size: 3.9375vw;
  }

  .works-grid .row {
    width: 100%;
    height: 97vw;
    background-size: cover;
    background-position: 50%;
  }

  .main-p-wp {
    width: fit-content;
    margin: auto;
  }

  .parag-cv-wp {
    width: 90vw;
    font-size: 7vw !important;
  }

  .info-work {
    display: none;
  }

  .parag-cv,
  .main-p-wp .text-anim-wp:first-of-type .parag-cv,
  .p-num {
    font-size: 7.3593vw !important;
    width: 100%;
    margin: 0;
    display: inline;
  }

  .title-cv {
    font-size: 6vw;
  }

  .review {
    grid-template-columns: 15% 85%;
    align-items: flex-start;
  }

  .cv-job,
  .parag-testimonial,
  .parag-cv-wp ul li {
    font-size: 5.041vw;
  }

  .review p {
    font-size: 4vw;
  }

  .review img {
    margin-top: 1em;
  }

  .personal-img {
    position: static;
  }

  .mt-120 {
    margin: 0 !important;
  }

  .ml-10 {
    margin-left: 0 !important;
  }

  .mt-50 {
    margin-top: 0 !important;
  }

  .mt-70 {
    margin-top: 0 !important;
  }

  .mb-150 {
    margin-bottom: 0 !important;
  }

  .mb-80 {
    margin-bottom: 80px !important;
  }

  .mt-120 {
    margin-top: 0 !important;
  }

  .main-p-wp .text-anim-wp {
    width: 100%;
  }

  .p-num {
    margin: 0 0 12vh 0;
  }

  .circular-btn-wp-mobile {
    display: grid !important;
    grid-template-columns: 15vw auto 45vw;
    align-items: center;
    margin-top: 5vh;
  }

  #learn-more-p {
    width: fit-content;
    font-size: 1.072vw;
    margin: 0;
    font-weight: 500;
  }

  .circular-btn-mob {
    width: 8vw;
  }

  .row {
    width: 90%;
    height: 2px;
    background-color: var(--default-lilac);
    margin: 0 auto;
  }

  .main-title-wp {
    width: 100%;
    margin: 5vh 0 0 0;
    position: relative;
    height: 70vh;
  }

  .main-title-h,
  .cursive-title-h {
    font-size: 8.6666vw;
    line-height: 1.5;
  }

  .mp-wp {
    height: fit-content;
  }

  .second-col-g2 {
    margin-top: 6vh;
  }

  .per-hobbies {
    margin-top: 6vh !important;
  }

  .mp-wp .per-hobbies p {
    width: 97vw;
  }

  .second-col-g2 p {
    width: 95%;
    margin: auto;
    font-size: 5vw;
  }

  .circular-btn-mob .material-symbols-outlined {
    font-size: 4vw;
  }

  .mob-who-am-i-wp {
    width: 95%;
    margin: auto;
    margin-bottom: 6vh;
  }

  .mp-wp .cursive-title-h {
    width: fit-content !important;
    font-size: 8.5vw !important;
  }

  .mob-who-am-i-wp > div:nth-child(1) {
    position: relative;
  }

  .mob-who-am-i-wp > div:nth-child(1) > .parag-cv {
    position: absolute;
    right: 5%;
    top: -0.4vh;
    width: fit-content;
  }

  .mp-wp .text-anim-wp {
    margin: 0.7vh 0;
  }

  .mp-wp .p-num {
    font-size: 5vw !important;
  }

  .mp-wp .parag-cv {
    font-size: 6.5vw !important;
  }

  .cursive-title-h {
    width: fit-content;
    padding-right: 5px;
    display: inline-block;
  }

  .first-col-g2 {
    width: 100%;
    display: grid;
    place-items: center;
  }

  .img-home-l {
    width: 95%;
    margin: auto;
  }

  .main-logo-wp {
    bottom: 10vh;
  }

  .subt-wp {
    position: absolute;
    bottom: 5vh;
    left: 0;
    width: 100%;
  }

  .subtitle-h {
    font-size: 4.6666vw;
  }

  .sx,
  .dx {
    display: none;
  }
}

.loader-ll-w {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notistack-SnackbarContainer {
  z-index: 999999 !important;
}
